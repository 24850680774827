import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Auth0Provider} from "@auth0/auth0-react";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <Auth0Provider
          domain="tryyew.us.auth0.com"
          clientId="bmlxqd7qq4RmMQ9ockNvqZ7kHFniLP9w"
          authorizationParams={{
              redirect_uri: window.location.origin,
              audience: `https://api.yewfi.com/operations`,
              scope: "read:current_user",
          }}
      >
    <App />
      </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
