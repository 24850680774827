import React, { useState, useEffect } from 'react';
import { FaceReview } from './types';
import { Select, Button } from 'antd/lib';
const { Option } = Select;

import './FaceReviewScreen.css';
import {Col, Row} from "antd";

interface ReviewProps {
    read: boolean
    write: boolean
}

const FaceReviewScreen: React.FC<ReviewProps> = (props) => {
    const [faceReview, setFaceReview] = useState<FaceReview | null>(null);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const [isModalVisible, setModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const backendPath = 'https://api.staging.yewpay.com'
    // const backendPath = 'http://localhost:5000'

    const fetchNextFaceReview = async () => {
        if (!props.read) return;
        setLoading(true)
        const response = await fetch(`${backendPath}/review/face/next`);
        const data = await response.json();
        console.log(JSON.stringify(data, null, 2))
        setLoading(false);
        if (data == null) {
            setFaceReview(null);
            return;
        }

        setFaceReview(data);
        setValidationErrors([]);
    };

    const handleReview = async (result: boolean) => {
        if (!faceReview || !props.write) return;
        setLoading(true)
        faceReview.review_result = result ? 'MATCH' : 'NO_MATCH'
        console.log(`Submitting face review: ${JSON.stringify(faceReview, null, 2)}`)
        await fetch(`${backendPath}/review/face`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(faceReview),
        })
        setLoading(false)
        fetchNextFaceReview();
    };

    useEffect(() => {
        fetchNextFaceReview();
    }, [props.read, props.write]);


    if (!faceReview && !loading) return <div>No face instance available to review</div>;
    if (!faceReview && loading) return <div>Fetching review...</div>;

    return (
        <div className="review-container">
            <div style={{marginTop: '20px', display: 'flex', width: '100%', flexDirection: 'column'}}>
                <Row style={{width: '100%', justifyContent: 'center'}}>
                    <Col xs={24} sm={24} md={18} lg={12}>
                        <Button size={'large'} onClick={() => handleReview(true)} type={'primary'} block
                                disabled={loading || validationErrors.length > 0}>Match</Button>
                    </Col>
                </Row>
            </div>
            {validationErrors.map((error, index) => (
                <div key={index} style={{ color: 'red' }}>{error}</div>
            ))}
            <Row style={{width: '100%', justifyContent: 'center', marginTop: '20px'}}>
                <Col xs={24} sm={24} md={18} lg={12}>
                    <img src={faceReview?.base_img_signed} alt="Base" className="face-image" />
                </Col>
                <Col xs={24} sm={24} md={18} lg={12}>
                    <img src={faceReview?.comp_img_signed} alt="Comp" className="face-image" />
                </Col>
            </Row>
            <div style={{marginTop: '20px', display: 'flex', width: '100%', flexDirection: 'column'}}>
                <Row style={{width: '100%', justifyContent: 'center', marginTop: '20px'}}>
                    <Col xs={24} sm={24} md={18} lg={12}>
                        <Button size={'large'} disabled={loading} onClick={() => handleReview(false)} type={'primary'} danger block style={{marginRight: '10px'}}>
                            No Match
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default FaceReviewScreen;
