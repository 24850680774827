import React, {useEffect, useState} from 'react';
import './App.css';
import { useAuth0 } from "@auth0/auth0-react";
import jwt from 'jwt-decode';
import {ConfigProvider} from "antd";
import FaceReviewScreen from "./FaceReviewScreen";


function App() {
    const { user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const domain = 'tryyew.us.auth0.com';
    const [permissions, setPermissions] = useState<any>([]);

    useEffect(() => {
        if (isAuthenticated) {
            getAccessTokenSilently({
                authorizationParams: {
                    audience: `https://api.yewfi.com/operations`,
                    scope: "read:current_user",
                }
            })
                .then((accessToken) => {
                    console.log(`AccessToken: ${accessToken}`)
                    if (!accessToken) {
                        console.error('No access token returned');
                        return;
                    }

                    try {
                        const token: any = jwt(accessToken);
                        console.log(JSON.stringify(token));
                        console.log(`Setting permissions: ${JSON.stringify(token.permissions)}`)
                        setPermissions(token.permissions);
                    } catch (e) {
                        console.error('Error decoding the JWT', e);
                    }
                })
                .catch((err) => {
                    console.error('Error getting access token', err);
                });
        } else if (!isLoading) {
            loginWithRedirect();
        }
    }, [isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently]);

  return (
      <ConfigProvider theme={{ token: { fontFamily: 'Montserrat', colorPrimary: '#7ac968' }}}>
          <div className="App">
          <header className="App-header">
              {permissions && permissions.includes('read:faces') &&
                  <FaceReviewScreen read={permissions.includes('read:faces')} write={permissions.includes('write:faces')}/>
              }
          </header>
        </div>
      </ConfigProvider>
  );
}

export default App;
